import { COUNTRIES, EVENTS, PubSub, queryClient, UserStore } from '@rc-d2c/context-manager';
import { RcButton } from '@rc-d2c/shared-ui/RcButton';
import { RcTag } from '@rc-d2c/shared-ui/RcTag';
import React from 'react';
import Link from 'next/link';
import { useStore } from 'zustand';

import Login from '../Login';
import NavLink from '../NavLink';
import RemoteComponent from '../../pages/components/RemoteComponent';
import styles from '../../styles/common.module.scss';
import { useIsClient, useLocalStorage } from 'usehooks-ts';
import { getDefaultStore } from '../../helpers/store';

// @ts-expect-error the path is unkown because it belongs to the federated module
const cartPanelToggleRemote = () => import('cart/cartPanelToggle');

export default function Navbar() {
  const { isMswEnable, setIsMswEnable, reset, setD2cToken, setData } = useStore(UserStore, (state) => state);
  const [store] = useLocalStorage<COUNTRIES>('app-shell-store', getDefaultStore());
  const isClient = useIsClient();

  const toggleTranslationKeys = () => {
    PubSub.publish(EVENTS.DEBUG_TRANSLATIONS_KEYS);
  };

  const toggleMSW = () => {
    if (!isClient) {
      return;
    }

    if (isMswEnable) {
      reset();
      queryClient.clear();
    }

    setIsMswEnable(!isMswEnable);
    window.location.replace(window.location.origin);
  };

  return (
    <header className={styles.header}>
      <h1 className={styles.title}>
        D2C Shell application
        <small className={styles.version}>CM v{process.env.contextManagerVersion}</small>
        <RcButton
          customClasses={styles.debug_translations_button}
          onClick={toggleTranslationKeys}
          label="Toggle translation keys"
          variant="hyperlink"
        />
        <RcButton
          customClasses={styles.debug_translations_button}
          onClick={toggleMSW}
          label={isMswEnable ? 'Disable MSW' : 'Enable MSW'}
          variant="hyperlink"
        />
        <RcTag color="success" variant="solid">
          {isClient && store.toUpperCase()}
        </RcTag>
        <RcTag color="warning" variant="solid">
          {process.env.NEXT_PUBLIC_LEGACY_APIS === 'true' ? 'LEGACY APIs' : 'COMMERCE TOOLS APIs'}
        </RcTag>
      </h1>

      <div className={styles.navigationContainer}>
        <div className={styles.linksContainer}>
          <NavLink path="/" label="Home" />
          <NavLink path="/components-demo" label="Components" />
          <NavLink path="/subscriptions/list" label="Subscriptions" version={process.env.subscriptionMfeVersion} />
          <NavLink path="/cart" label="Cart" version={process.env.cartMfeVersion} />
          <NavLink path="/my-account" label="MEA" />
          <NavLink path="/recommended-products" label="Recommended products" />
          <NavLink path="/reorder-products" label="Reorder products" />
          <NavLink path="/addresses" label="Addresses" />
          <NavLink path="/checkout" label="Checkout" version={process.env.checkoutMfeVersion} />
        </div>
        <RemoteComponent loadComponentModuleFn={cartPanelToggleRemote} props={{ linkComponent: Link }} />
      </div>
      <div className={styles.loginButtonContainer}>
        <Login />
      </div>
    </header>
  );
}
